<template lang="pug">
div.container
  h1 Patch Notes
  p.mb-5 Hand curated list of release highlights. All updates tend to include minor bug fixes and UX improvements.

  ///
    h4 ???
      small.text-muted  - ???
    ul
      li Added feature 1
      li Fixed last feature

    hr

  hr

  h4 3.11.2
    small.text-muted  - Quality of Life
  ul
    li New landing page look for easier navigation.
    li Large deps update to Node 16 support.
    li Fixed broken inline styles due to strict Content Security Policy directives.

  hr

  h4 3.10.1
    small.text-muted  - Academic Affiliates
  ul
    li Added the concept of academic affiliates!
    li Usability improvements to the affiliates list.
    li New affiliate duplicate function for faster affiliate renew workflow.
    li Lots of dependency updates to keep things more secure.
    li Tuned content policy settings for better cross domain attack protection.

  hr

  h4 3.9.1
    small.text-muted  - Plumming Updates
  ul
    li Reverse proxy updated to Caddy 2 because we live in the future!
    li Fixed a crash bug when updating affiliate members

  hr

  h4 3.8.2
    small.text-muted  - IGDA Finland ❤️ Privacy
  ul
    li Changed the way digital membership card links work so now it's much more anonymous and difficult to data mine
    li Updates and fixes to membership CSV exports to make IGDA org elections easier

  hr

  h4 3.7.0
    small.text-muted  - Legal Compliance Upgrades & Affiliate Workflows
  p: i We never store any payment details and jump extra hoops to verify payments without ever transmitting your credit card details to our servers. Nothing to hack!
  ul
    li Complete rewrite of payment processing to keep us compliant with the upcoming EU law requiring SCA
    li Removed address collecting for individual members since we no longer need it for the physical cards
    li UX improvements for dealing with affiliates (sorting, deleting, exporting, etc.)
    li Added affiliates and CSV exports to membership change log (very useful for IGDA org updates and members-only events)
    li Lots of sanity checks to enforce lowercase emails everywhere
    li More robust handling of multi-year affiliate members' digital membership cards

  hr

  h4 3.6.1
    small.text-muted  - Digital Membership Workflows
  ul
    li No more physical card orders! Removed a lot of code for that.
    li Added CSV export buttons to affiliate members
    li More detailed membership changelog that now includes affiliates and valid memberships for time ranges

  hr

  h4 3.5.3
    small.text-muted  - Patch notes
  ul
    li Added this page! Also included some of the recent past 😉
    li Added links to this page in landing page and top navigation
    li Added current backend version #[small.text-muted (it's {{globalState.version}}!)] to status API response -> it's shown on the landing page now
    li More robust checkin handling. This potentially fixes some race condition errors we saw in March Helsinki gathering.
    li Nicer Slack reporting. Developers need features too!
    li Potentially fixed an error causing the search field to become unresponsive like we saw in April Helsinki gathering.
    li Fixed a crash when requesting and edit email for very old membership profiles
    li Fixed date picker theming

  hr

  h4 3.4.0
    small.text-muted  - Better session handling
  ul
    li Moved user info away from cookies -> got rid of some session expiration problems
    li More robust session expiration detection and handling
    li New client pages to handle 401 errors and next steps. For example: #[router-link(to="/400") error page]

  hr

  h4 3.3.1
    small.text-muted  - Dark mode
  p: i Switched to a free dark bootstrap 4 template and modified existing layout to look mostly sane with it. Not super pleasing to my own tastes but definitely less bright. Any help with better colors would be much appreciated.
  ul
    li Dark mode! 🎃
    li Slightly leaner digital membership cards with less CSS scaffolding

  hr

  h4 3.2.3
    small.text-muted  - Digital membership cards v1
  p: i This is sort of a pre-release of the upcoming cards. Everything should work for both individuals and affiliates but it's not yet fully wired in to replace plastic. Testing, ahoy!
  ul
    li New membership card API
    li New #[a(href="/card/") sub-website] with it's own bells and whistles that consumes the card API
    li Better internal self-healing from hardware reboots
    li Refactored existing membership APIs to work with affiliate members #[i (Oh man, that took some doing!)]

  hr

  h4 3.1.0
    small.text-muted  - Open source
  ul
    li Documentation improvements
    li Big improvements to dev environment portability (no more connecting to external databases with sensitive information etc.)
    li Sources available under MIT at #[a(href="http://gitlab.com/igdafi/emmi") Gitlab]
    li Added CSV export to individual members (was needed for IGDA org elections)
</template>

<script>
import globalState from '@/main.js'

export default {
  data: function () {
    return {
      globalState,
      admins: []
    }
  },
  mounted: function () {
    if (this.globalState.isLoggedIn) {
      this.axios.get('/api/roles/admins')
        .then(res => (this.admins = res.data))
        .catch(err => (this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching admins failed',
          text: err.response.data,
          duration: -1
        })))
    }
  }
}
</script>
